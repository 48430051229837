export type CollectionPaths = "nikhil" | "jonkjetil" | "mickey";

export const getCollectionIds = (): CollectionPaths[] => [
  "nikhil",
  "jonkjetil",
  "mickey",
];

export function getOwnerAndDocumentIds(): object[] {
  const ownerAndDocumentIds = [
    { name: "nikhil", documentId: "1q3HFfEM58R7qt5DJvQG" },
    { name: "jk", documentId: "amlEX44TB0Y7jR8Tnaam" },
    { name: "mickey", documentId: "wlalZQq12YJeGWuh5s4h" },
  ];
  return ownerAndDocumentIds;
}

export function getOrderFromStorage(): CollectionPaths[] {
  const order = window.localStorage.getItem("order");

  if (order) {
    return JSON.parse(order);
  } else {
    window.localStorage.setItem("order", JSON.stringify(getCollectionIds()));
  }

  return getCollectionIds();
}

export function saveOrderToStorage() {
  const inputs =
    document.querySelectorAll<HTMLInputElement>(".order-list input");

  const values: string[] = [];
  inputs.forEach((input) => {
    values.push(input.value);
  });

  if (
    values.indexOf("0") > -1 &&
    values.indexOf("1") > -1 &&
    values.indexOf("2") > -1
  ) {
    const newOrder: string[] = [];
    values.forEach((value, index) => {
      newOrder[parseInt(value)] = getCollectionIds()[index];
    });

    window.localStorage.setItem("order", JSON.stringify(newOrder));
  }
}

export function getShowBestFromStorage() {
  const val = window.localStorage.getItem("showBest");
  return val === "true" ? true : false;
}

export function saveShowBestToStorage(checked: boolean) {
  window.localStorage.setItem("showBest", checked.toString());
}

export const ownerColor: Record<CollectionPaths, string> = {
  jonkjetil: "218 222 13",
  mickey: "85 191 59",
  nikhil: "250 0 0",
};
