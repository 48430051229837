import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBo9rJFQJ5_e2ql_OYhEQh3Tf9pRkS2gZ8",
  authDomain: "solarpanel-54a2b.firebaseapp.com",
  databaseURL:
    "https://solarpanel-54a2b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "solarpanel-54a2b",
  storageBucket: "solarpanel-54a2b.appspot.com",
  messagingSenderId: "282817934872",
  appId: "1:282817934872:web:de745459fdb3a134a3b56d",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;
