import { SolarData } from "../../types";
import { padNumber, roundNumber } from "../../utils";
import { ownerColor } from "../../settings";

const findIndexes = (
  data: SolarData["production_today_chart"]
): { firstIndex: number; lastIndex: number } => {
  if (data.length === 0) {
    return { firstIndex: 0, lastIndex: 0 };
  }
  const hasValue = (x: number | null) => x !== null && x > 0;
  const firstIndex = data.findIndex(hasValue);
  let lastIndex = data.length;
  // TODO upgrade typescript to latest to get findLastIndex support
  // const lastIndex = data.findLastIndex((x) => x !== null && x > 0);

  for (var i = data.length; i > 0; i--) {
    if (hasValue(data[i])) {
      lastIndex = i + 1;
      break;
    }
  }

  return { firstIndex, lastIndex };
};

export const generateDataTimestamps = (
  data: SolarData["production_today_chart"]
) => {
  let titles = [];
  const max = data.length;
  var k = 0;
  for (var i = 0; i < 24; i++) {
    for (var j = 0; j < 60; j += 5) {
      if (k > max) break;
      k++;
      titles.push(padNumber(i) + ":" + padNumber(j));
    }
    if (k > max) break;
  }

  return titles;
};

type RGB = string;
export type ChartData = {
  name: string;
  color: RGB;
  min: number;
  max: number;
  firstIndex: number;
  lastIndex: number;
  peak: {
    power: number;
    timestamp: string;
  };
  timestamps: string[];
  data: SolarData["production_today_chart"];
};

export const productionSeries = (
  data: SolarData,
  name = "Today",
  color = "54 195 40"
): ChartData => {
  const chartData = data.production_today_chart;
  const positiveData = chartData.filter((x) => x) as number[];
  const max = Math.max(...positiveData);
  const min = Math.min(...positiveData);

  const timestamps = generateDataTimestamps(chartData);
  const highestPeakIndex = chartData.indexOf(max);

  const { firstIndex, lastIndex } = findIndexes(chartData);

  const colors = ownerColor as Record<string, string>;
  color = colors[name] ? colors[name] : color;

  name = data.owner;

  return {
    name,
    color,
    min,
    max,
    timestamps,
    data: chartData,
    firstIndex,
    lastIndex,
    peak: {
      power: chartData[highestPeakIndex] ?? 0,
      timestamp: timestamps[highestPeakIndex],
    },
  };
};

export const productionFactorSeries = (
  data: SolarData,
  name = "Today",
  color = "54 195 40"
): ChartData => {
  const maxCapacityWatt = data.watt_per_panel * data.number_of_panels;
  const chartData = data.production_today_chart.map((watt) =>
    typeof watt === "number"
      ? roundNumber(watt / (maxCapacityWatt / 100), 2)
      : watt
  );

  const positiveData = chartData.filter((x) => x) as number[];
  const max = Math.max(...positiveData);
  const min = Math.min(...positiveData);

  const timestamps = generateDataTimestamps(chartData);
  const highestPeakIndex = chartData.indexOf(max);

  const { firstIndex, lastIndex } = findIndexes(chartData);

  const colors = ownerColor as Record<string, string>;
  color = colors[name] ? colors[name] : color;

  name = data.owner;

  return {
    name,
    color,
    min,
    max,
    timestamps,
    data: chartData,
    firstIndex,
    lastIndex,
    peak: {
      power: chartData[highestPeakIndex] ?? 0,
      timestamp: timestamps[highestPeakIndex],
    },
  };
};

const generateDayStamps = (data: SolarData["production_month_chart"]) =>
  data.map((_, index) => `${index + 1}`);

export const monthSeries = (
  data: SolarData,
  name = "Today",
  color = "54 195 40"
): ChartData => {
  const chartData = data.production_month_chart;

  const positiveData =
    chartData.length > 0 ? (chartData.filter((x) => x) as number[]) : [0];
  const max = Math.max(...positiveData);
  const min = Math.min(...positiveData);

  const timestamps = generateDayStamps(chartData);
  const highestPeakIndex = chartData.indexOf(max);

  const { firstIndex, lastIndex } = findIndexes(chartData);

  const colors = ownerColor as Record<string, string>;
  color = colors[name] ? colors[name] : color;

  name = data.owner;

  return {
    name,
    color,
    min,
    max,
    timestamps,
    data: chartData,
    firstIndex,
    lastIndex,
    peak: {
      power: chartData[highestPeakIndex] ?? 0,
      timestamp: timestamps[highestPeakIndex],
    },
  };
};

const generateMonthStamps = () => [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sept",
  "oct",
  "nov",
  "des",
];

export const yearSeries = (
  data: SolarData,
  name = "Today",
  color = "54 195 40"
): ChartData => {
  const chartData = data.production_year_chart;

  const positiveData =
    chartData.length > 0 ? (chartData.filter((x) => x) as number[]) : [0];
  const max = Math.max(...positiveData);
  const min = Math.min(...positiveData);

  const timestamps = generateMonthStamps();
  const highestPeakIndex = chartData.indexOf(max);

  const { firstIndex, lastIndex } = findIndexes(chartData);

  const colors = ownerColor as Record<string, string>;
  color = colors[name] ? colors[name] : color;

  name = data.owner;

  return {
    name,
    color,
    min,
    max,
    timestamps,
    data: chartData,
    firstIndex,
    lastIndex,
    peak: {
      power: chartData[highestPeakIndex] ?? 0,
      timestamp: timestamps[highestPeakIndex],
    },
  };
};
