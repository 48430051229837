import { SolarData, ComputedData } from "../types";
import { CollectionPaths } from "../settings";

export type SolarPanelSystemData = SolarData & ComputedData;
export type Collections = Record<CollectionPaths, SolarPanelSystemData>;

export const initData = {
  owner: "init",
  number_of_panels: 0,
  last_meter_production: 0,
  lifetime_energy_output: 0,
  peakToday: 0,
  peakTodayTimestamp: 0,
  power_surplus_now: 0,
  production_today_kwh: 0,
  solar_production_now_w: 0,
  temperature: 0,
  timestamp: new Date().toISOString(),
  watt_per_panel: 0,
  weather: "",
  wind_bearing: 0,
  wind_speed: 0,
  downPaymentPercent: 0,
  currentPriceKwhOre: 0,
  production_today_chart: [],
  production_month_chart: [],
  production_year_chart: [],
};
