import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import {
  getOrderFromStorage,
  saveOrderToStorage,
  getShowBestFromStorage,
  saveShowBestToStorage,
} from "../../settings";

import "./settings.scss";

export default function Settings() {
  const [showSettings, setShowSettings] = useState(false);
  const [showBest, setShowBest] = useState(false);

  useEffect(() => {
    setShowBest(getShowBestFromStorage());
  }, [setShowBest]);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const showBestChanged = (checked: boolean) => {
    setShowBest(checked);
    saveShowBestToStorage(checked);
  };

  const getPanelOrder = () => {
    const order = getOrderFromStorage();
    return order.map((owner: any, index: number) => (
      <div key={index}>
        <span>{owner}</span>
        <span>
          <input
            onChange={saveOrderToStorage}
            type="number"
            defaultValue={index}
          />
        </span>
      </div>
    ));
  };

  return (
    <>
      <nav>
        {
          //TODO move menu out of here
        }
        <div className="stats-link">
          <Link to="/">
            <i className="fa-solid fa-house"></i>
          </Link>
          {/* <Link to="/stats">
            <i className="fa-solid fa-chart-line"></i>
          </Link> */}
        </div>
        <div onClick={toggleSettings} className="settings">
          {!showSettings && (
            <span title="Settings">
              <i className="fa-solid fa-gear"></i>
            </span>
          )}
          {showSettings && (
            <span title="Close settings">
              <i className="fa-solid fa-circle-xmark"></i>
            </span>
          )}
        </div>
      </nav>

      {showSettings && (
        <div className="settings-modal">
          <h3>Settings</h3>
          <div className="setting-container">
            <div className="setting solar-panel-systems-order">
              <h4>Order</h4>
              <div className="order-list">{getPanelOrder()}</div>
            </div>
            <div className="setting show-best-numbers">
              <h4>Highlight best</h4>
              <div>
                <Switch onChange={showBestChanged} checked={showBest} />
              </div>
            </div>
          </div>
          <p>Remember to reload browser after changing a setting! (sorry)</p>
        </div>
      )}
    </>
  );
}
