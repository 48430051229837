import { useEffect } from "react";
import { formatToTime, isStaleData, roundNumber } from "../../utils";
import { productionSeries } from "../charts/utils";
import { updateBestOf } from "./bestOf";
import { Chart } from "../charts/Chart";
import Weather from "./../Weather/Weather";
import LastUpdated from "./../LastUpdated/LastUpdated";
import "./solarpanelsystem.scss";
import { SolarPanelSystemData } from "../../data/solar";

type SolarPanelSystemProps = {
  data: SolarPanelSystemData;
};

export default function SolarPanelSystem({ data }: SolarPanelSystemProps) {
  useEffect(() => {
    updateBestOf();
  }, [data]);

  const calculateSoldPercentage = () => {
    if (!data.last_meter_production || !data.lifetime_energy_output) return;

    return (
      <small>
        {`(${roundNumber(
          (data.last_meter_production / data.lifetime_energy_output) * 100
        )}%)`}
      </small>
    );
  };

  const productionPerPanel =
    data.solar_production_now_w / data.number_of_panels || 0;

  const maxCapacityKwh = (data.watt_per_panel * data.number_of_panels) / 1000;
  const productionNowPercent = roundNumber(
    (data.solar_production_now_w / (maxCapacityKwh * 1000)) * 100
  );

  const productionSurplusPercent = roundNumber(
    (data.power_surplus_now / (data.solar_production_now_w || 1)) * 100
  );
  const producedCapacityFactor = roundNumber(
    data.production_today_kwh / maxCapacityKwh,
    2
  );

  let peakToday = data.peakToday;
  let peakTodayTimestamp = formatToTime(data.peakTodayTimestamp);

  const hasTodayChart = data.production_today_chart.length > 0;
  const chartData = productionSeries(data);

  if (hasTodayChart) {
    peakToday = chartData.peak.power;
    peakTodayTimestamp = chartData.peak.timestamp;
  }
  return data.owner === "init" ? (
    <span>Loading data</span>
  ) : (
    <div
      className={`solar-panel-system ${
        isStaleData(data.timestamp) ? "stale" : ""
      }`}
    >
      <h1>{data.owner}</h1>
      <LastUpdated lastUpdated={data.timestamp} />
      <ul className="system-properties">
        <li>
          <Weather
            temperature={data.temperature}
            weather={data.weather}
            windBearing={data.wind_bearing}
            windSpeed={data.wind_speed}
          />
        </li>
        <li className="production-now" data-value={data.solar_production_now_w}>
          <span>
            Production now
            <small>{` (${productionNowPercent}%)`}</small>:
          </span>
          <strong>{roundNumber(data.solar_production_now_w)} w</strong>
        </li>
        <li className="production-surplus" data-value={data.power_surplus_now}>
          <span>
            Production surplus
            <small>{` (${productionSurplusPercent}%)`}</small>:
          </span>
          <strong>{data.power_surplus_now} w</strong>
        </li>

        <li className="production-today" data-value={data.production_today_kwh}>
          <span>Produced today:</span>
          <strong>{data.production_today_kwh} kWh</strong>
        </li>
        <li
          className="production-today-factor"
          data-value={producedCapacityFactor}
        >
          <span>Produced capacity factor:</span>
          <strong>{producedCapacityFactor} x</strong>
        </li>
        <li className="peak-today" data-value={peakToday}>
          <span>
            Peak today{" "}
            {peakTodayTimestamp && (
              <small>{`(kl.${peakTodayTimestamp})`}</small>
            )}
            :
          </span>
          <strong>{roundNumber(peakToday)} w</strong>
        </li>

        <li>
          <span>Down payment:</span>
          <strong>{data.downPaymentPercent} %</strong>
        </li>
        <li>
          <details>
            <summary>Details</summary>
            <ul className="solar-panel-system">
              <li
                className="production-per-panel"
                data-value={productionPerPanel}
              >
                <span>Production per panel:</span>
                <strong>{roundNumber(productionPerPanel)} w</strong>
              </li>
              <li className="peak-today" data-value={peakToday}>
                <span>Peak today per panel:</span>
                <strong>
                  {roundNumber(peakToday / data.number_of_panels)} w
                </strong>
              </li>
              <li>
                <span>Lifetime produced:</span>
                <strong>{roundNumber(data.lifetime_energy_output)} kWh</strong>
              </li>
              <li>
                <span>Lifetime sold {calculateSoldPercentage()}:</span>
                <strong>{roundNumber(data.last_meter_production)} kWh</strong>
              </li>
              <li>
                <span>
                  Sale price, kWh
                  <small> (excl. mva)</small>:
                </span>
                <strong>{data.currentPriceKwhOre} øre</strong>
              </li>
              <li>
                <span>
                  Max capacity
                  <small>{` (${data.number_of_panels} x ${data.watt_per_panel} w)`}</small>
                  :
                </span>
                <strong>{maxCapacityKwh} kWp</strong>
              </li>
            </ul>
            {hasTodayChart && (
              <div className="chart">
                <Chart series={[chartData]} />
              </div>
            )}
          </details>
        </li>
      </ul>
    </div>
  );
}
