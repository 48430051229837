export function formatToDate(timestamp: string) {
  let d = new Date(timestamp);
  if (d.toString() === "Invalid Date") {
    return timestamp;
  }

  return formatDateFromDateObject(d);
}

export function formatToTime(timestamp: string | number) {
  let d = new Date(Number(timestamp));
  return `${d.getHours() < 10 ? "0" : ""}${d.getHours()}:${
    d.getMinutes() < 10 ? "0" : ""
  }${d.getMinutes()}`;
}

export function isStaleData(timestamp?: string) {
  const timesince = new Date().valueOf() - new Date(timestamp || 0).valueOf();

  if (timesince >= 60 * 30 * 1000) {
    return true;
  }

  return false;
}

export function timestampToDateOnly(timestamp: string | number | undefined) {
  if (typeof timestamp == "undefined") {
    return;
  }

  let d = new Date(Number(timestamp));
  let date = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  return `${date}.${month}.${year}`;
}

function formatDateFromDateObject(d: Date) {
  let date = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();
  let hours = d.getHours();
  let minutes = d.getMinutes();

  return `${date}.${month}.${year} ${padNumber(hours)}:${padNumber(minutes)}`;
}

export const padNumber = (number: number) =>
  number < 10 ? "0" + number : number.toString();

export async function fetchChartData(url: string) {
  let response = await fetch(url);
  let result = await response.json();
  return result;
}

export function createWeatherObject(data: Array<any>) {
  type Weather = { [key: string]: Object };
  let weather: Weather = {};
  data.forEach((d) => {
    weather[createDateKeyFromTimestamp(d.date)] = {
      temperature: d.temperature,
      description: d.description,
    };
  });
  console.log(weather);
  return weather;
}

export function createDateKeyFromTimestamp(timestamp: any) {
  let dateObj = new Date(Number(timestamp));
  return (
    dateObj.getDate() + "" + (dateObj.getMonth() + 1) + dateObj.getFullYear()
  );
}
type RoundNumber = (number: number, deciamls?: number) => number;
export const roundNumber: RoundNumber = (num, decimals = 0): number => {
  const coefficient = parseInt("1".padEnd(1 + decimals, "0"));
  return Math.round(num * coefficient) / coefficient;
};
