import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useRef } from "react";
import { ChartData } from "./utils";
import * as R from "ramda";

var defaultOptions: Highcharts.Options = {
  chart: {
    type: "areaspline",
    zooming: { type: "x" },
    plotShadow: false,
    plotBackgroundColor: "var(--background-color)",
    backgroundColor: "none",
    style: {
      fontFamily: "inherit",
    },
  },
  credits: {
    enabled: false,
  },

  title: { text: "" },
  yAxis: {
    // minTickInterval: 500,
    tickInterval: 1000,
    // lineColor: "blue",
    gridLineColor: "var(--text-color)",
    // tickColor: "green",
    title: {
      text: "",
      style: {
        color: "var(--text-color)",
        fontSize: "13px",
        letterSpacing: "1px",
      },
    },
    labels: {
      format: "{value:.0f}w",
      style: {
        fontSize: "14px",
        color: "var(--text-color)",
      },
    },
  },
  xAxis: {
    minTickInterval: 30,
    title: {
      text: "",
      style: {
        color: "var(--text-color)",
        fontSize: "13px",
        letterSpacing: "1px",
      },
    },
    labels: {
      format: "{value:.0f}",
      style: {
        fontSize: "14px",
        color: "var(--text-color)",
      },
    },
  },
  tooltip: {
    headerFormat:
      '<span style="color:var(--text-color); font-size:1rem;">{point.y:.0f}w @ kl.{point.key}</span></br>',
    pointFormat: "",
    footerFormat: "",

    shared: true,
    backgroundColor: "var(--background-color)",
    borderColor: "#000",
  },
  plotOptions: {
    column: {
      pointPadding: 0,
      borderWidth: 0,
    },
    series: {
      connectNulls: true,
      marker: {
        enabled: false,
      },
    },
    spline: {
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 2,
        },
      },
    },
  },
  legend: {
    itemStyle: {
      color: "#f6f4ff",
    },
  },
  noData: {
    style: {
      fontSize: "0px",
      color: "#ffffff",
    },
  },
};

export type DayChartProps = {
  series: ChartData[];
  type?: "areaspline" | "column" | "spline";
  options?: Highcharts.Options;
};

export const Chart = ({
  series,
  type = "areaspline",
  options: overrideOptions = {},
}: DayChartProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const firstIndex = Math.min(...series.map((x) => x.firstIndex));
  const lastIndex = Math.max(...series.map((x) => x.lastIndex));
  const min = Math.min(...series.map((x) => x.min));
  const max = Math.max(...series.map((x) => x.max));
  const { timestamps } = series.find((s) => s.timestamps.length > 0) || {
    timestamps: [],
  };

  const initOptions = R.mergeDeepRight(
    defaultOptions,
    overrideOptions
  ) as Highcharts.Options;

  const options: Highcharts.Options = {
    ...initOptions,
    chart: {
      ...initOptions.chart,
      type,
    },
    yAxis: {
      ...initOptions.yAxis,
      max,
      min,
    },
    xAxis: {
      ...initOptions.xAxis,
      categories: timestamps.slice(firstIndex, lastIndex),
    },
    series: series.map(({ name, data, color }) => ({
      name,
      type,
      data: data.slice(firstIndex, lastIndex),
      fillColor: `rgba(${color} / 0.1)`,
      lineColor: `rgba(${color} / 1)`,
      color: `rgba(${color} / 1)`,
      borderColor: `rgba(${color} / 0.1)`,
    })),
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
};
