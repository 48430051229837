import { getShowBestFromStorage } from "../../settings";

export function updateBestOf() {
  if (!getShowBestFromStorage()) {
    return;
  }

  updateBest(".solar-panel-system:not(.stale) .production-now");
  updateBest(".solar-panel-system:not(.stale) .production-surplus");
  updateBest(".solar-panel-system:not(.stale) .production-per-panel");
  updateBest(".solar-panel-system:not(.stale) .production-today");
  updateBest(".solar-panel-system:not(.stale) .peak-today");
  updateBest(".solar-panel-system:not(.stale) .production-today-factor");
}

const updateBest = (listItem: string) => {
  const items = [...document.querySelectorAll<HTMLElement>(listItem)];

  const values = items.map((el) => {
    el.classList.remove("best");
    return Number(el.dataset.value);
  });

  const everythingIsNotZero = values.some((value) => value !== 0);

  if (everythingIsNotZero) {
    let i = values.indexOf(Math.max(...values));

    if (i > -1) {
      items[i].classList.add("best");
    }
  }
};
