import "./weather.scss";

const weatherSymbols: Record<string, string> = {
  "Broken Clouds": "cloudy",
  "Overcast Clouds": "cloudy",
  partlycloudy: "partlycloudy_day",
  "Partly cloudy": "partlycloudy_day",
  Fair: "fair_day",
  "Scattered Clouds": "fair_day",
  "Few Clouds": "fair_day",
  "Light rain showers": "lightrainshowers_day",
  "Moderate Rain": "rain",
  rainy: "rain",
  snowy: "snow",
  clearsky: "clearsky_day",
  "Clear sky": "clearsky_day",
  sunny: "clearsky_day",
  "snowy-rainy": "sleet",
  "Light Shower Sleet": "lightsleet",
};

const getIconName = (iconName: string) => {
  let fileName = iconName.replaceAll(" ", "").toLowerCase();
  if (weatherSymbols[iconName]) {
    fileName = weatherSymbols[iconName];
  }

  return `/weather_icons/${fileName}.svg`;
};

type WeatherProps = {
  temperature?: number;
  weather?: string;
  windBearing?: number;
  windSpeed?: number;
};

export default function Weather({
  temperature,
  weather,
  windBearing,
  windSpeed,
}: WeatherProps) {
  return (
    <div className="weather">
      <span>
        {weather && (
          <img title={weather} alt={weather} src={getIconName(weather)} />
        )}
      </span>
      <span>
        {temperature && (
          <>
            <strong>{temperature}</strong> °C
          </>
        )}
      </span>
      <span className="wind-bearing">
        {windBearing && (
          <span
            title="Vindretning"
            style={{
              transform: `rotate(${windBearing}deg)`,
            }}
          >
            <strong>↑</strong>
          </span>
        )}
      </span>
      <span>
        {windSpeed && (
          <>
            <strong>{windSpeed}</strong> m/s
          </>
        )}
      </span>
    </div>
  );
}
