import { Collections } from "../../data/solar";
import {
  ChartData,
  productionSeries,
  productionFactorSeries,
  monthSeries,
  yearSeries,
} from "../../components/charts/utils";
import { Chart, DayChartProps } from "./Chart";
import LastUpdated from "../../components/LastUpdated/LastUpdated";
import { isStaleData } from "../../utils";

type CollectionsDayChartProps = {
  collections: Collections;
  series: ChartData[];
  options?: Highcharts.Options;
  title?: string;
  type?: DayChartProps["type"];
};

export const CollectionsDayChart = ({
  collections,
  series,
  options,
  title = "Production today",
  type = "areaspline",
}: CollectionsDayChartProps) => {
  if (series.length < 0) {
    return null;
  }
  const timestamps = Object.entries(collections).map(
    ([_, data]) => data.timestamp
  );
  const isStale = !timestamps.find((timestamp) => !isStaleData(timestamp));
  const lastUpdated = timestamps.sort()[timestamps.length - 1];

  return (
    <div className={`${isStale ? "stale" : ""}`}>
      <h1>{title}</h1>
      <LastUpdated lastUpdated={lastUpdated} />
      <Chart series={series} type={type} options={options} />
    </div>
  );
};

export const ProductionDayChart = ({
  collections,
}: Omit<CollectionsDayChartProps, "series">) => {
  const series = Object.entries(collections).map(([collectionPath, data]) =>
    productionSeries(data, collectionPath)
  );

  const multiSeriesConfig: Highcharts.Options = {
    tooltip: {
      headerFormat:
        '<span style="color:var(--text-color); font-size:1rem;">@ kl.{point.key}</span></br>',
      pointFormat:
        '<span style="color:{point.color}; font-size:1rem;">{series.name}:</span> <span style="color:var(--text-color);font-size:1rem">{point.y:.0f}w</span><br/>',
    },
  };

  return (
    <CollectionsDayChart
      series={series}
      collections={collections}
      options={multiSeriesConfig}
    ></CollectionsDayChart>
  );
};

export const ProductionEffectivenessDayChart = ({
  collections,
}: Omit<CollectionsDayChartProps, "series">) => {
  const series = Object.entries(collections).map(([collectionPath, data]) =>
    productionFactorSeries(data, collectionPath)
  );

  const options: Highcharts.Options = {
    yAxis: {
      labels: {
        format: "{value:.0f}%",
        style: {
          fontSize: "14px",
          color: "var(--text-color)",
        },
      },
      tickInterval: 10,
    },
    tooltip: {
      headerFormat:
        '<span style="color:var(--text-color); font-size:1rem;">@ kl.{point.key}</span></br>',
      pointFormat:
        '<span style="color:{point.color}; font-size:1rem;">{series.name}:</span> <span style="color:var(--text-color);font-size:1rem">{point.y:.0f}%</span><br/>',
    },
  };

  return (
    <CollectionsDayChart
      title="Production effectiveness today"
      series={series}
      collections={collections}
      options={options}
    ></CollectionsDayChart>
  );
};

export const MonthChart = ({
  collections,
}: Omit<CollectionsDayChartProps, "series">) => {
  const series = Object.entries(collections)
    .map(([collectionPath, data]) => monthSeries(data, collectionPath))
    .filter((series) => series.data.length > 0);

  const options: Highcharts.Options = {
    yAxis: {
      labels: {
        format: "{value}kwt",
        style: {
          fontSize: "14px",
          color: "var(--text-color)",
        },
      },
      tickInterval: 10,
    },
    xAxis: {
      crosshair: true,
      minTickInterval: 1,
    },
    tooltip: {
      headerFormat:
        '<span style="color:var(--text-color); font-size:1rem;">{point.x}</span></br>',
      pointFormat:
        '<span style="color:{point.color}; font-size:1rem;">{series.name}:</span> <span style="color:var(--text-color);font-size:1rem">{point.y}kwt</span><br/>',
    },
  };

  return (
    <CollectionsDayChart
      title="Production per day"
      series={series}
      collections={collections}
      options={options}
      type="column"
    ></CollectionsDayChart>
  );
};

export const YearChart = ({
  collections,
}: Omit<CollectionsDayChartProps, "series">) => {
  const series = Object.entries(collections)
    .map(([collectionPath, data]) => yearSeries(data, collectionPath))
    .filter((series) => series.data.length > 0);

  const options: Highcharts.Options = {
    yAxis: {
      labels: {
        format: "{value}kwt",
        style: {
          fontSize: "14px",
          color: "var(--text-color)",
        },
      },
      tickInterval: 500,
    },
    xAxis: {
      crosshair: true,
      minTickInterval: 1,
    },
    tooltip: {
      headerFormat:
        '<span style="color:var(--text-color); font-size:1rem;">{point.x}</span></br>',
      pointFormat:
        '<span style="color:{point.color}; font-size:1rem;">{series.name}:</span> <span style="color:var(--text-color);font-size:1rem">{point.y}kwt</span><br/>',
    },
  };

  return (
    <CollectionsDayChart
      title="Production per month"
      series={series}
      collections={collections}
      options={options}
      type="column"
    ></CollectionsDayChart>
  );
};
