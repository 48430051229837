import { isStaleData, formatToDate } from "../../utils";
import "./lastupdated.scss";
type LastUpdatedProps = {
  lastUpdated?: string;
};

export default function lastUpdated({ lastUpdated }: LastUpdatedProps) {
  return (
    <div className="lastUpdated">
      <small className={isStaleData(lastUpdated) ? "stale-data" : ""}>
        Last updated: {lastUpdated ? formatToDate(lastUpdated) : "N/A"}
      </small>
    </div>
  );
}
