import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";

import "./App.scss";
import Settings from "./components/Settings/Settings";

function App() {
  return (
    <div className="App">
      <Router>
        <Settings />
        <Switch>
          {/* <Route path="/stats">
            <Stats />
          </Route> */}
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
