import { useState, useEffect, useMemo } from "react";

import SolarPanelSystem from "../../components/SolarPanelSystem/SolarPanelSystem";
import { CollectionPaths as Owners, getOrderFromStorage } from "../../settings";
import classes from "./home.module.scss";
import db from "../../firebase";

import { initData, SolarPanelSystemData } from "../../data/solar";
import {
  MonthChart,
  ProductionEffectivenessDayChart,
  YearChart,
} from "../../components/charts/CollectionsDayChart";

const fallbackJSON = (data: (number | null)[] | string) =>
  data && data !== "undefined" ? JSON.parse(data as unknown as string) : [];

const subscribeData = (
  owner: Owners,
  setData: React.Dispatch<React.SetStateAction<SolarPanelSystemData>>
) =>
  db.collection(owner).onSnapshot((snapshot) => {
    snapshot.docs.forEach((doc) => {
      const data = doc.data() as SolarPanelSystemData;

      const parsedData: SolarPanelSystemData = {
        ...data,
        production_today_chart: fallbackJSON(data.production_today_chart),
        production_month_chart: fallbackJSON(data.production_month_chart),
        production_year_chart: fallbackJSON(data.production_year_chart),
      };

      setData(parsedData);
    });
  });

const Home = () => {
  const owners = getOrderFromStorage();
  const [nikhil, setNikhil] = useState<SolarPanelSystemData>(initData);
  const [jonkjetil, setJonkjetil] = useState<SolarPanelSystemData>(initData);
  const [mickey, setMickey] = useState<SolarPanelSystemData>(initData);

  useEffect(() => {
    const nikhilUnsubscribe = subscribeData("nikhil", setNikhil);
    const jonkjetilUnsubscribe = subscribeData("jonkjetil", setJonkjetil);
    const mickeyUnsubscribe = subscribeData("mickey", setMickey);

    return () => {
      nikhilUnsubscribe();
      jonkjetilUnsubscribe();
      mickeyUnsubscribe();
    };
  }, []);

  const collections = useMemo(
    () => ({ nikhil, jonkjetil, mickey }),
    [jonkjetil, mickey, nikhil]
  );

  return (
    <div className={classes.home}>
      <div className={classes.systems}>
        {owners.map((owner) => {
          return <SolarPanelSystem key={owner} data={collections[owner]} />;
        })}
      </div>
      <div className={classes.collectionsDayChart}>
        <ProductionEffectivenessDayChart collections={collections} />
        <MonthChart collections={collections} />
        <YearChart collections={collections} />
      </div>
    </div>
  );
};

export default Home;
